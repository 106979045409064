<template>
  <Page :classes="['page--vcenter']">

    <div class="auth">
      <div class="auth__header">
        <h1 class="auth__title">{{$t('auth.closing_session')}}</h1>
      </div>
      <Spinner :classes="['spinner--center']"></Spinner>
    </div>

  </Page>
</template>

<script>
export default {
  name: 'Logout',
  mounted() {
    this.$store.dispatch('logout')
      .then(() => {
        window.location.href = '/';
      });
  },
};
</script>
